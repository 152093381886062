const brandsConfigs = {
  linker: {
    brand: "Linker",
    logo1: "linker-media/logos/logo@2x.png",
    logo2: "linker-media/logos/logo@2xWhite.png",
    favicon: "favicon_new.ico",
  },
  reachMore: {
    brand: "ReachMore",
    logo1: "reach-more-media/reach_more_white.svg",
    logo2: "reach-more-media/reach_more_white.svg",
    favicon: "reach-more-media/favicon_reach_more.ico",
  },
};

export const configs =
  process.env.VUE_APP_BRAND === "Linker"
    ? brandsConfigs.linker
    : brandsConfigs.reachMore;
